<template>
  <section>
    <b-row class="match-height">
      <b-col xl="3" md="6">
        <b-card class="card-transaction" no-body>
          <b-card-header>
            <b-card-title>Informasi Umum</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Nomor Ujian</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">088</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Kode Paket</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">002</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Profesi</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">Programmer</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-warning">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Durasi</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">100 Menit</div>
            </div>
          </b-card-body>
        </b-card>
         <b-card class="card-transaction" no-body>
          <b-card-header>
            <b-card-title>Informasi Soal</b-card-title>
          </b-card-header>

          <b-card-body>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-info">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Jumlah</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">60</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-info">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Dijawab</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">35</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-info">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Ragu-Ragu</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">7</div>
            </div>
            <div class="transaction-item">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="40" variant="light-info">
                    <feather-icon
                      size="18"
                      icon="AlertCircleIcon"
                      variant="warning"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <p></p>
                  <h6 class="transaction-title">Belum Dijawab</h6>
                </b-media-body>
              </b-media>
              <div class="font-weight-bolder">18</div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xl="6" md="6">
        <b-card no-body class="business-card">
          <b-card-header class="pb-1">
            <b-card-title>Pertanyaan ke 15 / 60</b-card-title>
          </b-card-header>

          <b-card-body>
            <b-card-text
              >Seorang laki-laki berusia 46 tahun di antar keluarga ke
              poliklinik dengan keluhan: sesak nafas. Hasil pengkajian: nyeri
              dada kiri, batuk, kondisi nyeri lebih hebat saat berjalan,
              karakteristik nyeri yang hilang timbul. Skala Nyeri 7/10, pasien
              mengeluh aktivitas terbatas, cepat merasakan capek, pada
              pemeriksaan tanda-tanda vital TD : 190/90 mmHg, frekuensi nadi 112
              x/mnt, suhu : 37 C, frekuensi napas : 40 kali/mnt. Apakah masalah
              utama pada kasus diatas?</b-card-text
            >
            <h6 class="mb-75">Jawaban</h6>
            <div class="business-items">
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-radio name="some-radios" value="A">
                    Spongebob Squarepants
                  </b-form-radio>
                </div>
              </div>
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-radio name="some-radios" value="A">
                    SquidWard
                  </b-form-radio>
                </div>
              </div>
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-radio name="some-radios" value="A">
                    Patrik Star
                  </b-form-radio>
                </div>
              </div>
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-radio name="some-radios" value="A">
                    Unyil dan Ipin
                  </b-form-radio>
                </div>
              </div>
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-radio name="some-radios" value="A">
                    Wakwaw
                  </b-form-radio>
                </div>
              </div>
            </div>

            <!-- button -->
            <b-row>
              <b-col md="6" xl="4">
                <b-button variant="warning" style="width: 100%">
                  <feather-icon icon="ArrowLeftIcon" style="color: white" />
                  <span class="align-middle"
                    ><b style="color: white"> Sebelumnya</b></span
                  >
                </b-button>
              </b-col>
              <b-col md="6" xl="4"> </b-col>
              <b-col md="6" xl="4">
                <b-button variant="success" style="width: 100%">
                  <span class="cursor-pointer"
                    ><b style="color: white">Selanjutnya </b></span
                  >
                  <feather-icon icon="ArrowRightIcon" style="color: white" />
                </b-button>
              </b-col>
            </b-row>
            <p></p>
            <b-form-checkbox value="D" class="custom-control-warning">
              Ragu-Ragu
            </b-form-checkbox>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xl="3" md="6">
        <b-card>
          <b-table-simple hover small caption-top responsive>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="light">
              <b-tr>
                <b-th colspan="5" class="text-center"> Jejak Jawaban </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td class="text-center" variant="success">1</b-td>
                <b-td class="text-center" variant="success">2</b-td>
                <b-td class="text-center" variant="success">3</b-td>
                <b-td class="text-center" variant="success"> 4 </b-td>
                <b-td class="text-center" variant="success">5</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">6</b-td>
                <b-td class="text-center" variant="success">7</b-td>
                <b-td class="text-center" variant="success">8</b-td>
                <b-td class="text-center" variant="warning"> 9 </b-td>
                <b-td class="text-center" variant="success">10</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">11</b-td>
                <b-td class="text-center" variant="success">12</b-td>
                <b-td class="text-center" variant="warning">13</b-td>
                <b-td class="text-center" variant="success"> 14 </b-td>
                <b-td class="text-center" variant="success">15</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">16</b-td>
                <b-td class="text-center" variant="success">17</b-td>
                <b-td class="text-center" variant="success">18</b-td>
                <b-td class="text-center" variant="warning"> 19 </b-td>
                <b-td class="text-center" variant="success">20</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">21</b-td>
                <b-td class="text-center" variant="success">22</b-td>
                <b-td class="text-center" variant="success">23</b-td>
                <b-td class="text-center" variant="success"> 24 </b-td>
                <b-td class="text-center" variant="success">25</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">26</b-td>
                <b-td class="text-center" variant="warning">27</b-td>
                <b-td class="text-center" variant="success">28</b-td>
                <b-td class="text-center" variant="warning"> 29 </b-td>
                <b-td class="text-center" variant="success">30</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">31</b-td>
                <b-td class="text-center" variant="warning">32</b-td>
                <b-td class="text-center" variant="success">33</b-td>
                <b-td class="text-center" variant="success"> 34 </b-td>
                <b-td class="text-center" variant="success">35</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">36</b-td>
                <b-td class="text-center" variant="warning">37</b-td>
                <b-td class="text-center" variant="success">38</b-td>
                <b-td class="text-center" variant="success"> 39 </b-td>
                <b-td class="text-center" variant="success">40</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="success">41</b-td>
                <b-td class="text-center" variant="success">42</b-td>
                <b-td class="text-center" variant="secondary">43</b-td>
                <b-td class="text-center" variant="secondary"> 44 </b-td>
                <b-td class="text-center" variant="secondary">45</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="secondary">46</b-td>
                <b-td class="text-center" variant="secondary">47</b-td>
                <b-td class="text-center" variant="secondary">48</b-td>
                <b-td class="text-center" variant="secondary"> 49 </b-td>
                <b-td class="text-center" variant="secondary">50</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="secondary">51</b-td>
                <b-td class="text-center" variant="secondary">52</b-td>
                <b-td class="text-center" variant="secondary">53</b-td>
                <b-td class="text-center" variant="secondary"> 54 </b-td>
                <b-td class="text-center" variant="secondary">55</b-td>
              </b-tr>
              <b-tr>
                <b-td class="text-center" variant="secondary">56</b-td>
                <b-td class="text-center" variant="secondary">57</b-td>
                <b-td class="text-center" variant="secondary">58</b-td>
                <b-td class="text-center" variant="secondary"> 59 </b-td>
                <b-td class="text-center" variant="secondary">60</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      getUsername: "",
      getPic: "",
      getPic_2: "",
      getDepartemen: "",
      getSap: "",
      getName: localStorage.getItem("Ufullname"),
      getType: "",
      getLevel: "",
      getPartkpi: "",
      nameMonth: "",
    };
  },
  mounted() {
    this.getMonthName();
  },
  methods: {
    getMonthName() {
      const date = new Date(); // 2009-11-10
      const month = date.toLocaleString("default", { month: "long" });
      this.nameMonth = month;
    },
  },
};
</script>
